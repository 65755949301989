<template>
  <div class="upload-container">
    <el-upload
      :data="data"
      :multiple="false"
      :show-file-list="false"
      :on-success="handleImageSuccess"
      class="image-uploader"
      drag
      :action="uploadurl"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        Drag files here or <em>Click to upload</em>
      </div>
    </el-upload>
    <div v-show="imageUrl" class="image-preview image-app-preview">
      <div class="image-preview-wrapper">
        <img :src="imageUrl">
        <div class="image-preview-action">
          <i class="el-icon-delete" @click="rmImage" />
        </div>
      </div>
    </div>
    <!--div class="image-preview">
      <div v-show="imageUrl.length>1" class="image-preview-wrapper">
        <img :src="imageUrl">
        <div class="image-preview-action">
          <i class="el-icon-delete" @click="rmImage" />
        </div>
      </div>
    </div-->
  </div>
</template>

<script>
export default {
  name: 'SingleImageUpload3',
  props: {
    value: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    uploadurl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // uploadUrl: this.$appbaseUrl + 'articles/upload/images',
      tempUrl: '',
      // additionalData: {},
    };
  },
  computed: {
    imageUrl() {
      return this.value;
    },
  },
  methods: {
    converted(data){
      return data;
    },
    getBase64Image(src, callback, outputFormat) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL = '';
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    rmImage() {
      this.emitInput('');
    },
    emitInput(val) {
      this.$emit('input', val);
    },
    handleImageSuccess(file) {
      this.emitInput(file.files.file);
    },
  },
};
</script>
<style>
.upload-container .image-app-preview {
    width: 80% !important;
    height: auto !important;
    margin: auto !important;
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "~@/styles/mixin.scss";
.upload-container {
  width: 100%;
  position: relative;
  @include clearfix;
  .image-uploader {
    width: 35%;
    float: left;
  }
  .image-preview {
    width: 200px;
    height: 200px;
    position: relative;
    border: 1px dashed #d9d9d9;
    float: left;
    margin-left: 50px;
    .image-preview-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, .5);
      transition: opacity .3s;
      cursor: pointer;
      text-align: center;
      line-height: 200px;
      .el-icon-delete {
        font-size: 36px;
      }
    }
    &:hover {
      .image-preview-action {
        opacity: 1;
      }
    }
  }
  .image-app-preview {
    width: 320px;
    height: 180px;
    position: relative;
    border: 1px dashed #d9d9d9;
    float: left;
    margin-left: 50px;
    .app-fake-conver {
      height: 44px;
      position: absolute;
      width: 100%; // background: rgba(0, 0, 0, .1);
      text-align: center;
      line-height: 64px;
      color: #fff;
    }
  }
}
</style>
